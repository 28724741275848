@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@506&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@500&display=swap");
.App {
  text-align: center;
}

body {
  font-family: "Inter", sans-serif;
  color: white;
  background-color: #18181b;
}

.footer {
  bottom: 10px;
  width: 100%;
  color: white;
  text-align: center;
}

.typing-text {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Source Code Pro", monospace;
  font-size: 28px;
  color: rgba(255, 255, 255, 0.75);
  animation: animated-text 4s steps(29, end) 1s 1 normal both;
}

@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    width: 340px;
  }
}
.card {
  border-radius: 11px;
  background: #18181b;
  box-shadow: inset -5px 5px 10px #111113, inset 5px -5px 10px #1f1f23;
}/*# sourceMappingURL=App.css.map */